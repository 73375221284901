<template>
    <ValidationObserver
        class="material-edit"
        tag="div"
        ref="observer"
        v-if="isLoaded"
    >
        <div class="material-edit__block material-edit__block--share">
            <div class="material-edit__block-body material-edit__share">
                <button :disabled="isSending" @click="onSubmit" class="v-btn">
                    Сохранить изменения
                </button>
                <router-link
                    :to="
                        materialId
                            ? `/knowledge/article/${materialId}`
                            : `/knowledge`
                    "
                >
                    <icon>
                        <path
                            d="M17 1L1 17"
                            stroke="#989CAE"
                            stroke-width="2"
                        ></path
                        ><path
                            d="M1 1L17 17"
                            stroke="#989CAE"
                            stroke-width="2"
                        ></path>
                    </icon>
                </router-link>
            </div>
        </div>
        <!--        <div class="material-edit__block material-edit__draft">
            <div class="material-edit__block-body">
                JS
            </div>
        </div>-->
        <div class="material-edit__block material-edit__block--content">
            <div class="material-edit__block-body material-edit__content">
                <div class="content__control-panel ">
                    <div class="control-panel__row">
                        <div
                            class="control-panel__item"
                            title="Сделать материал видимым для всех, у кого есть доступ к нему."
                        >
                            <toggle-button
                                class="m-0"
                                :color="colors"
                                :height="20"
                                :width="40"
                                v-model="form.published"
                                :sync="true"
                            />
                            <span class="control-panel__margin">Опубликован</span>
                        </div>

                        <div
                            class="control-panel__item"
                            title="Разместить информацию об обновлении материала на главной странице."
                        >
                            <toggle-button
                                class="m-0"
                                :color="colors"
                                :height="20"
                                :width="40"
                                v-model="form.published_wall"
                                :sync="true"
                            />
                            <span class="control-panel__margin">Оповестить об изменение материала</span>
                        </div>

                        <div class="control-panel__item">
                            <icon width="14" height="14">
                                <path
                                    d="M7 0C3.14005 0 0 3.14005 0 7C0 10.86 3.14005 14 7 14C10.86 14 14 10.86 14 7C14 3.14005 10.86 0 7 0ZM10.3291 10.6207C10.2153 10.7345 10.066 10.7917 9.9167 10.7917C9.76738 10.7917 9.61795 10.7345 9.5043 10.6207L6.5876 7.7041C6.47791 7.59505 6.4167 7.44679 6.4167 7.2917V3.5C6.4167 3.17743 6.67796 2.9167 7 2.9167C7.32204 2.9167 7.5833 3.17743 7.5833 3.5V7.0502L10.3291 9.7959C10.5571 10.024 10.5571 10.3927 10.3291 10.6207Z"
                                    fill="#989CAE"
                                />
                            </icon>
                            <span class="control-panel__margin"
                            >Время на изучение:</span
                            >
                            <number-input
                                id="id_timer"
                                class="control-panel__margin"
                                v-model="form.timepiece"
                                size="small"
                                :min="1"
                                :max="999"
                                style="width: 80px;"
                            />
                        </div>
                        <div class="control-panel__item">
                            <icon width="18" height="18">
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M3 9C3.82843 9 4.5 8.32843 4.5 7.5C4.5 6.67157 3.82843 6 3 6C2.17157 6 1.5 6.67157 1.5 7.5C1.5 8.32843 2.17157 9 3 9Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M5.0025 10.05C4.61324 10.343 4.29746 10.7226 4.08008 11.1586C3.86271 11.5947 3.74971 12.0753 3.75 12.5625V12.75H0.5625C0.255 12.75 0 12.495 0 12.1875V11.8125C0 10.6725 0.9225 9.75 2.0625 9.75H3.9375C4.3275 9.75 4.695 9.8625 5.0025 10.05Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M15 9C15.8284 9 16.5 8.32843 16.5 7.5C16.5 6.67157 15.8284 6 15 6C14.1716 6 13.5 6.67157 13.5 7.5C13.5 8.32843 14.1716 9 15 9Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M17.9996 11.8125V12.1875C17.9996 12.495 17.7446 12.75 17.4371 12.75H14.2496V12.5625C14.2496 11.535 13.7546 10.62 12.9971 10.05C13.3046 9.8625 13.6721 9.75 14.0621 9.75H15.9371C17.0771 9.75 17.9996 10.6725 17.9996 11.8125Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M9 9.375C10.2426 9.375 11.25 8.36764 11.25 7.125C11.25 5.88236 10.2426 4.875 9 4.875C7.75736 4.875 6.75 5.88236 6.75 7.125C6.75 8.36764 7.75736 9.375 9 9.375Z"
                                        fill="#989CAE"
                                    />
                                    <path
                                        d="M11.0625 10.5H6.9375C6.39061 10.5004 5.86624 10.7178 5.47953 11.1045C5.09282 11.4912 4.8754 12.0156 4.875 12.5625V13.6875C4.875 13.998 5.127 14.25 5.4375 14.25H12.5625C12.7117 14.25 12.8548 14.1907 12.9602 14.0852C13.0657 13.9798 13.125 13.8367 13.125 13.6875V12.5625C13.1246 12.0156 12.9072 11.4912 12.5205 11.1045C12.1338 10.7178 11.6094 10.5004 11.0625 10.5Z"
                                        fill="#989CAE"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="18" height="18" fill="white" />
                                    </clipPath>
                                </defs>
                            </icon>
                            <div
                                class="control-panel__margin"
                                @click="changePermission"
                            >
                                {{
                                    permissionInfo
                                        ? permissionInfo.info_text
                                        : 'Доступен 0 пользователям'
                                }}
                                <span class="control-panel__permission v-link"
                                >Настроить</span
                                >
                            </div>
                        </div>

<!--                        <div class="control-panel__item">-->
<!--                            <icon width="14" height="14" icon-color="#989CAE">-->
<!--                                <path d="M11.375 0H5.25C4.55381 0 3.88613 0.276562 3.39384 0.768845C2.90156 1.26113 2.625 1.92881 2.625 2.625V4.375C2.625 4.60706 2.71719 4.82962 2.88128 4.99372C3.04538 5.15781 3.26794 5.25 3.5 5.25C3.73206 5.25 3.95462 5.15781 4.11872 4.99372C4.28281 4.82962 4.375 4.60706 4.375 4.375V2.625C4.375 2.39294 4.46719 2.17038 4.63128 2.00628C4.79538 1.84219 5.01794 1.75 5.25 1.75H11.375C11.6071 1.75 11.8296 1.84219 11.9937 2.00628C12.1578 2.17038 12.25 2.39294 12.25 2.625V8.75C12.25 8.98206 12.1578 9.20462 11.9937 9.36872C11.8296 9.53281 11.6071 9.625 11.375 9.625H9.625C9.39294 9.625 9.17038 9.71719 9.00628 9.88128C8.84219 10.0454 8.75 10.2679 8.75 10.5C8.75 10.7321 8.84219 10.9546 9.00628 11.1187C9.17038 11.2828 9.39294 11.375 9.625 11.375H11.375C12.0712 11.375 12.7389 11.0984 13.2312 10.6062C13.7234 10.1139 14 9.44619 14 8.75V2.625C14 1.92881 13.7234 1.26113 13.2312 0.768845C12.7389 0.276562 12.0712 0 11.375 0Z"/>-->
<!--                                <path d="M0.875 11.375H2.625V13.125C2.625 13.3571 2.71719 13.5796 2.88128 13.7437C3.04538 13.9078 3.26794 14 3.5 14C3.73206 14 3.95462 13.9078 4.11872 13.7437C4.28281 13.5796 4.375 13.3571 4.375 13.125V11.375H6.125C6.35706 11.375 6.57962 11.2828 6.74372 11.1187C6.90781 10.9546 7 10.7321 7 10.5C7 10.2679 6.90781 10.0454 6.74372 9.88128C6.57962 9.71719 6.35706 9.625 6.125 9.625H4.375V7.875C4.375 7.64294 4.28281 7.42038 4.11872 7.25628C3.95462 7.09219 3.73206 7 3.5 7C3.26794 7 3.04538 7.09219 2.88128 7.25628C2.71719 7.42038 2.625 7.64294 2.625 7.875V9.625H0.875C0.642936 9.625 0.420376 9.71719 0.256282 9.88128C0.0921874 10.0454 0 10.2679 0 10.5C0 10.7321 0.0921874 10.9546 0.256282 11.1187C0.420376 11.2828 0.642936 11.375 0.875 11.375Z"/>-->
<!--                            </icon>-->

<!--                            <span class="control-panel__margin">-->
<!--                                Создано: {{ '21 декабря 2023 08:02' | moment("DD MMMM, HH:mm") }}.-->
<!--                                Кем:-->
<!--                                <router-link-->
<!--                                    :to="`/auth/profile/79`"-->
<!--                                    target="_blank"-->
<!--                                    title="Админов Админ Админович"-->
<!--                                    class="control-panel__author"-->
<!--                                >Админов Админ Админович</router-link>-->
<!--                            </span>-->
<!--                        </div>-->

<!--                        <div class="control-panel__item">-->
<!--                            <icon width="14" height="14" icon-color="#989CAE">-->
<!--                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7145 1.6462L12.4444 0.369928C11.9536 -0.123309 11.1566 -0.123309 10.6641 0.369928L9.44749 1.59247L12.2954 4.45433L13.7145 3.02829C14.0952 2.64571 14.0952 2.02876 13.7145 1.6462ZM9.4465 1.60769L12.2944 4.46952L4.34456 12.4424L1.49827 9.58053L9.4465 1.60769ZM0.399972 13.9905C0.166692 14.0475 -0.0439032 13.8375 0.00792506 13.6031L0.727195 10.3554L3.57349 13.2173L0.399972 13.9905Z"/>-->
<!--                            </icon>-->

<!--                            <span class="control-panel__margin">-->
<!--                                Отредактировано: {{ '21 декабря 2023 08:02' | moment("DD MMMM, HH:mm") }}.-->
<!--                                Кем:-->
<!--                                <router-link-->
<!--                                    :to="`/auth/profile/79`"-->
<!--                                    target="_blank"-->
<!--                                    title="Админов Админ Админович"-->
<!--                                    class="control-panel__author"-->
<!--                                >Админов Админ Админович</router-link>-->
<!--                            </span>-->
<!--                        </div>-->
                    </div>
                </div>

                <ValidationProvider
                    tag="div"
                    vid="text"
                    v-slot="{ errors }"
                    :rules="`required`"
                >
                    <new-editor
                        class="content__editor"
                        v-model="form.text"
                        top-placeholder="Название материала"
                        bottom-placeholder="Описание материала"
                        :data="form.text"
                    />
                    <span
                        class="v-error animated fadeInDown"
                        id="error_text"
                        v-if="errors.length"
                    >
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>
            </div>
        </div>
    </ValidationObserver>
    <loading-spinner v-else />
</template>

<script>
    import ContentView from '@/views/menu/ContentView'
    import Icon from '@/components/icon/Icon'
    import session from '@/api/session'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import { metaTitle } from '@/mixins/meta_title'
    import {mapGetters, mapState} from 'vuex'
    import { ValidateEditorVideo } from '../../mixins/validate-editor-video'
    import NewEditor from '../../components/editor/newEditor'

    export default {
        name: 'MaterialChange',
        mixins: [metaTitle],
        components: {
            NewEditor,
            LoadingSpinner,
            Icon,
            ContentView
        },
        data() {
            return {
                form: {
                    timepiece: 20
                },
                materialId: this.$route.params.material_id,
                parentId: this.$route.params.parent_id,
                isLoaded: false,
                isSending: false,
                colors: { checked: '#FBC04F', unchecked: '#EEEEEE' },
                permissionInfo: null,
                permissionAccess: {},
                isMaterialNew: false,
                isMaterialAdded: false,
                accessData: []
            }
        },
        computed: {
            ...mapState('knowledge_base', ['materialsList']),
            ...mapGetters('default_data', ['isAdmin']),

            setTitle() {
                return (
                    (this.isMaterialNew ? `Добавить` : `Редактировать`) +
                    ` материал`
                )
            }
        },
        methods: {
            changePermission() {
                let checkbox = this.form.rubric
                    ? 'Как у родительской рубрики'
                    : 'Для всей компании'

                if (this.materialId) {
                    const ModalPermissionUpdate = () =>
                        import(`@/components/permission/ModalPermissionUpdate`)
                    this.$modal.show(
                        ModalPermissionUpdate,
                        {
                            title: 'Настройка доступа к материалу',
                            checkbox: checkbox,
                            text:
                                'Выберите отделы, должности или сотрудников, которым необходим доступ к данному материалу',
                            permissionType: 'material',
                            permissionObjectID: this.materialId,
                            afterUpdate: this.getPermissionInfo
                        },
                        {
                            name: 'ModalPermissionUpdate',
                            adaptive: true,
                            maxWidth: 900,
                            width: '100%',
                            height: 'auto'
                        }
                    )
                } /*else {
                    const ModalPermissionCreate = () => import(`@/components/permission/ModalPermissionCreate`);
                    this.$modal.show(ModalPermissionCreate, {
                        title: 'Настройка доступа к материалу',
                        checkbox: checkbox,
                        text: 'Выберите отделы, должности или сотрудников, которым необходим доступ к данному материалу',
                        rubricID: this.form.rubric,
                        dataCache: this.permissionDataCache
                    }, {
                        name: 'ModalPermissionCreate',
                        adaptive: true,
                        maxWidth: 900,
                        width: '100%',
                        height: 'auto'
                    }, {
                        'before-close': this.updateTextPermission
                    })
                }*/
            },
            async getPermissionInfo(data) {
                let result = {}

                if (!data) {
                    const request = await session.get(
                        `/api/v1/permission/retrieve_vue/?permission_type=material&permission_object_id=${this.materialId}`
                    )

                    result = request.data
                } else {
                    result = data
                }

                this.permissionInfo = result
            },

            updateTextPermission() {},

            async onSubmit() {
                this.isSending = true;

                let isValid = await this.$refs.observer.validate()

                if (isValid) {
                    this.$Progress.start()
                    let { form } = this
                    let data = {
                        ...form,
                        text: form.text.html
                    }
                    let content = form.text.json.content
                    let contentTitle = content.find(
                        item => item.type === "title"
                    ).content
                    if (!content.length || !contentTitle) {
                        this.$refs.observer.setErrors({
                            text: ['Введите название материала']
                        })
                        this.$nextTick(() => {
                            this.$scrollTo('#error_text', { offset: 300 })
                        })
                        this.isSending = false
                        return false
                    } else {
                        data.title = contentTitle.map((variable) => variable.text).join('')
                    }

                    let isPublished = this.form.published
                    try {
                        let url = `/api/v1/material/${this.materialId}/`

                        data.is_draft = !isPublished

                        if (this.permissionInfo.access) {
                            data.access = {}
                            data.access.users = this.permissionInfo.access.users
                            data.access.all_users = this.permissionInfo.access.all_users
                        }

                        let saveRequest = await session.put(url, data)
                        let publishRequest = await session.post(
                            `/api/v1/material/${this.materialId}/publish/`
                        )
                        if (
                            this.$route.params.parent_id &&
                            !this.isMaterialAdded
                        ) {
                            this.$store.dispatch('knowledge_base/addMaterial', {
                                ...data,
                                id: this.materialId
                            })
                        } else {
                            this.$store.dispatch(
                                'knowledge_base/editMaterial',
                                {
                                    ...data,
                                    id: this.materialId
                                }
                            )
                        }
                        this.$router.push(
                            `/knowledge/article/${this.materialId}/`
                        )
                    } catch (err) {
                        // this.errorHandler(err);
                    }
                    this.$Progress.finish();
                    this.isSending = false;
                }
            },
            async onLoad() {
                const { materialId, parentId } = this

                if (materialId) {
                    await this.getPermissionInfo()
                    await Promise.all([
                        session.get(`/api/v1/material/${materialId}/data/`),
                        session.get(`/api/v1/material/${materialId}/`)
                    ]).then(([res1, res2]) => {
                        const data1 = res1.data;
                        const data2 = res2.data;

                        data1.text  = ValidateEditorVideo(data1.text)
                        this.form = data1

                        if (data1.is_draft) {
                            this.form.text = `${data1.text}<p></p>`
                        }

                        this.permissionInfo.access = {
                            users: data2.access ? data2.access.users : [],
                            all_users: data2.access ? data2.access.all_users : false
                        }
                    })
                } else {
                    let form = {
                        parent: parentId,
                        text: '<p></p>',
                        title: 'Без названия',
                        is_draft: true,
                        timepiece: 20,
                        published_wall: false,
                    }

                    this.form = form
                    this.isMaterialNew = true

                    let request = await session.post(`/api/v1/material/`, form)

                    this.materialId = request.data.id
                    this.permissionInfo.access = {
                        users: [],
                        all_users: false
                    }

                    this.$store.dispatch('knowledge_base/addMaterial', {
                        ...form,
                        id: this.materialId
                    })

                    this.isMaterialAdded = true
                    this.$router.push(`/knowledge/edit/${this.materialId}`)

                    // request = await session.post(`/api/v1/material/${this.materialId}/publish/`)
                }
            }
        },

        async created() {
            await this.onLoad()
            this.isLoaded = true
        },

        beforeDestroy() {}
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    .material-edit {
        width: 100%;
        height: 100%;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        &__block {
            width: 100%;
            &-body {
                display: flex;
                width: 100%;
                max-width: 1024px;
                margin: 0 auto;
            }

            &--share {
                top: 0px;
                position: sticky;
                z-index: 99999;
                width: auto;
                margin: 0px -30px;
                background-color: #fff;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
                @include below(1000px) {
                    top: 60px;
                }
            }
        }
        &__share {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
            @include below(1366px) {
                padding: 20px;
            }
        }
        &__draft {
            display: flex;
            padding: 10px 0;
            background-color: $gold;
            max-width: 100vw;
        }
        &__content {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            width: 100%;
            .content {
                &__control-panel {
                    .control-panel {
                        &__row {
                            margin: -5px -10px;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            @include below(500px) {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                        }

                        &__item {
                            color: #000;
                            margin: 5px 10px;
                            display: flex;
                            align-items: center;
                        }

                        &__margin {
                            margin-left: 10px;
                        }

                        &__permission {
                            cursor: pointer;
                        }

                        &__author {
                            max-width: 144px;
                            display: inline-block;
                            overflow: hidden;
                            white-space: nowrap;
                            vertical-align: bottom;
                            text-overflow: ellipsis;
                            text-decoration: underline;
                        }
                    }
                }
                &__editor {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }
    /*.material-edit {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        height: 100%;
        &--wrap {

        }
    }*/
</style>
<style lang="scss">
    @import '#sass/v-style';
    #id_timer {
        position: relative;
        &::after {
            position: absolute;
            transform: translate(-50%, -50%);
            right: 0;
            top: 50%;
            content: 'мин';
            font-size: 0.875rem;
            display: block;
            z-index: 99;
            color: $text-lightgray;
        }
        .number-input__input {
            padding-right: 45px;
        }
    }
</style>
